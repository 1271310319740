<template>
    <div class="add-comment__wrap main-content-wrap">
        <el-breadcrumb class="nav-list" separator="/">
            <el-breadcrumb-item :to="{ path: '/product' }">Find products</el-breadcrumb-item>
            <el-breadcrumb-item><a href="/">{{ (productDetail && productDetail.sellSkuName) || '' }}</a></el-breadcrumb-item>
            <el-breadcrumb-item><a href="/">Write a review</a></el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 商品新增 -->
        <div class="product-info">
            <div class="img-wrap">
                <rap-image :src="firstImgUrl"></rap-image>
            </div>
            <div class="info-wrap">
                <p class="skuName">{{ productDetail.sellSkuName }}</p>
                <!-- 星级 -->
                <div class="star-number">
                    <rap-rate v-model="productDetail.star"
                        disabled
                        show-score>
                    </rap-rate>
                    <div class="review-num">
                        {{ productDetail.commentNum }} Reviews
                    </div>
                </div>
            </div>
        </div>
        <!-- 新增 -->
        <div class="add-comment">
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="300px">
                <el-form-item label="Overall Rating" prop="star">
                    <div class="flex">
                        <rap-rate v-model="ruleForm.star" show-text :texts="texts"></rap-rate>
                        <span v-if="!ruleForm.star">Click on the stars to give the overall impression of the product</span>
                    </div>
                </el-form-item>
                <el-form-item label="Review Title" prop="title">
                    <el-input v-model="ruleForm.title"
                        placeholder="Shipping Address Detai"
                        clearable>
                    </el-input>
                </el-form-item>
                <el-form-item label="Review" prop="content">
                    <el-input v-model="ruleForm.content"
                        placeholder="Comments"
                        clearable
                        :rows="10"
                        type="textarea">
                    </el-input>
                </el-form-item>
                <el-form-item label="Value for Money" prop="starValueForMoney">
                    <div class="flex">
                        <rap-rate v-model="ruleForm.starValueForMoney" show-text :texts="texts"></rap-rate>
                    </div>
                </el-form-item>
                <el-form-item label="True to Description" prop="starTrueToDescription">
                    <div class="flex">
                        <rap-rate v-model="ruleForm.starTrueToDescription" show-text :texts="texts"></rap-rate>
                    </div>
                </el-form-item>
                <el-form-item label="Product Quality" prop="starProductQuality">
                    <div class="flex">
                        <rap-rate v-model="ruleForm.starProductQuality" show-text :texts="texts"></rap-rate>
                    </div>
                </el-form-item>
                <el-form-item label="Shipping" prop="starShipping">
                    <div class="flex">
                        <rap-rate v-model="ruleForm.starShipping" show-text :texts="texts"></rap-rate>
                    </div>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitReview">Post Review</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            productDetail: {},
            ruleForm: {
                star: 0,
                title: '',
                content: '',
                starValueForMoney: 0,
                starTrueToDescription: 0,
                starProductQuality: 0,
                starShipping: 0
            },
            rules: {
                star: [{ required: true, message: '', trigger: 'change' }],
                title: [{ required: true, message: '', trigger: 'change' }],
                content: [{ required: true, message: '', trigger: 'change' }]
            },
            texts: ['Very bad', 'Bad', 'General', 'Good', 'Very Good']
        }
    },
    computed: {
        firstImgUrl () {
            if (this.productDetail.images && this.productDetail.images.length) {
                return this.productDetail.images[0].imgUrl || ''
            }
            return ''
        }
    },
    created () {
        const params = this.$route.params
        this.reqProductDetail(params.skuId, params.spuId)
    },
    methods: {
        // 请求详情接口
        reqProductDetail (skuId, spuId) {
            this.$api.getProductInfo({
                skuId,
                spuId
            }).then(res => {
                if (res.code === 0) {
                    this.productDetail = res.data.skuDto || {}
                }
            })
        },
        // 提交评论
        submitReview () {
            this.$refs.ruleForm &&
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.reqSendReview()
                }
            })
        },
        // 请求接口
        reqSendReview () {
            this.$api.addComment({
                skuId: this.productDetail.skuId,
                skuName: this.productDetail.sellSkuName,
                star: this.ruleForm.star,
                title: this.ruleForm.title,
                content: this.ruleForm.content,
                starProductQuality: this.ruleForm.starProductQuality,
                starShipping: this.ruleForm.starShipping,
                starTrueToDescription: this.ruleForm.starTrueToDescription,
                starValueForMoney: this.ruleForm.starValueForMoney,
                orderId: '1'
            }).then(res => {
                if (res.code === 0) {
                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: 'Comment Success'
                    })
                    this.$router.back(-1)
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.add-comment__wrap {
    .nav-list {
        padding: 12px 0 18px 0;
        .el-breadcrumb__item {
            display: flex;
            flex-direction: row;
            /deep/ .el-breadcrumb__inner{
                color: #808080;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                a{
                    max-width: 160px;
                    @include ellipsis()
                }
            }
            &:last-of-type .el-breadcrumb__inner a{
                color: $mainPrimary;
            }
        }
    }
    .product-info{
        padding: 24px;
        background: white;
        border: 1px solid $borderColor;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        .img-wrap{
            width: 106px;
            height: 119px;
            flex-shrink: 0;
            margin-right: 34px;
        }
        .info-wrap {
            .skuName {
                font-size: 20px;
                font-family: PingFangSC, PingFangSC-Semibold;
                font-weight: 600;
                color: #1a1a1a;
                margin-bottom: 24px;
                @include ellipsis(2);
            }
            .star-number{
                margin-bottom: 10px;
                display: flex;
                flex-direction: row;
                align-items: center;
                .el-rate{
                    margin-right: 15px;
                    height: auto;
                }
                /deep/ .el-rate__icon {
                    font-size: 22px;
                    margin-right: 5px;
                }
                /deep/ .el-rate__text {
                    margin-left: 15px;
                    font-weight: 500;
                }
                .review-num{
                    color: $mainPrimary;
                    font-size: 12px;
                }
            }
        }
    }
    .add-comment{
        padding: 24px;
        margin-bottom: 24px;
        background: white;
        border: 1px solid $borderColor;
        .el-form-item{
            margin-bottom: 24px;
        }
        .el-rate{
            height: auto;
            margin-right: 17px;
        }
        /deep/ .el-form-item__label{
            font-size: 16px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 550;
            color: #000000;
            position: relative;
            padding-right: 20px;
            &::before {
                position: absolute;
                right: 4px;
            }
        }
        /deep/ .el-rate__icon {
            font-size: 38px;
            margin-right: 8px;
        }
        /deep/ .el-textarea__inner {
            width: 750px;
            height: 110px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            color: #4d4d4d;
            line-height: 20px;
        }
        /deep/ .el-input__inner {
            width: 750px;
        }
        .flex{
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 13px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            color: #4d4d4d;
        }
        .el-button{
            margin-top: 16px;
            width: 153px;
            height: 50px;
            background: #ff6c23;
            border-radius: 2px;
        }
    }
}
</style>
